<template>
  <div class="d-flex justify-center align-center flex-grow-1">
    <v-col cols="10" sm="6" md="4" lg="3" xl="3">
      <!-- Menu bar -->
      <v-app-bar app  v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" color="blue-grey" />

      <v-card class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Inicio de Sesión</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-card-text>
          <v-form>
            <VTextField
              v-model="email"
              :rules="[FormValidations.required, FormValidations.email]"
              label="E-mail"
              name="email"
              placeholder=" "
              prepend-icon="mdi-account"
              type="text"
              validate-on-blur
              @keyup.enter.native="handleSubmit"
            ></VTextField>

            <VTextField
              v-model="contrasena"
              :rules="[FormValidations.required]"
              label="Contraseña"
              name="contraseña"
              placeholder=" "
              prepend-icon="mdi-lock"
              :hint="`Se recomiendan mínimo ${PASSWORD_MIN} caracteres`"
              counter
              :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showpwd ? 'text' : 'password'"
              @click:append="showpwd = !showpwd"
              @keyup.enter.native="handleSubmit"
            ></VTextField>
          </v-form>

          <!-- <small>*campo requerido</small> -->
        </v-card-text>

        <v-card-actions style="margin: auto;">
          <v-spacer />
          <Button 
            color="primary" 
            @click="handleSubmit" 
            :loading="loading" 
            :disabled="loading"
            text 
          >
            Entrar
          </Button>
        </v-card-actions>
      </v-card>

      <v-footer v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" app elevation color="blue-grey" class="white--text">
        <span class="caption">&zwnj;</span>
      </v-footer>
    </v-col>

    <vue-element-loading 
      :active="fullPageLoading" 
      text="Iniciando sesión..." 
      :text-style="{ color: '#333', fontSize: '20px' }" 
      :is-full-screen="true"
    />
  </div>
</template>

<script>
import Button from "@/components/ui/Button";
import FormValidations from "@/utils/FormValidations";
import { getCurrentInstance, ref, onBeforeUpdate } from "vue";
import { APP_DEBUG_MODE, PASSWORD_MIN } from "@/utils/Constants";
import { isEmptyObj, setStorageItem } from "@/utils/Functions";
import { state, actions, getters, STORE_NAMESPACES } from "@/store";
import { abonosAPI } from "@/utils/Axios";

export default {
  props: {
    source: String
  },
  components: {
    Button,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, USERS, CLIENTES, PLANES } = STORE_NAMESPACES;

    const loading = ref(false);
    const showpwd = ref(false);
    const email = ref("");
    const contrasena = ref("");

    const fullPageLoading = ref(false);

    if (state[APP].expiredSession) {
      vm.proxy.$root.$toast.info("La sesión ha expirado", { timeout: 5000 });
      state[APP].expiredSession = false;
    }

    function handleSubmit() {
      loading.value = true;

      const data = {
        email: email.value,
        contrasena: contrasena.value,
      }

      APP_DEBUG_MODE && console.log("LOGIN (datos a enviar): ", data);
      actions[USERS].LOGIN(data, res => {
        APP_DEBUG_MODE && console.log("LOGIN (res): ", res);

        if (isEmptyObj(res)) {
          vm.proxy.$root.$toast.error("No se ha podido establecer una conexión con el servidor");
          loading.value = false;
        }
        else if (!res.success) {
          vm.proxy.$root.$toast.error(res.message, {timeout: 4000});
          loading.value = false;
        }
        else {
          fullPageLoading.value = true;

          abonosAPI.defaults.headers.common.Authorization = `Bearer ${res.token}`;
          state[APP].idToken = res.token;
          state[APP].idUser  = res.userId;
          
          setStorageItem("auth-token", res.token);
          setStorageItem("id-user", res.userId);

          APP_DEBUG_MODE && console.log("token: ", state[APP].idToken);
          APP_DEBUG_MODE && console.log("idUser: ", state[APP].idUser);

          actions[USERS].FETCH_ALL(users => {
            const logged_user = users.find(user => user.id == state[APP].idUser);

            if (logged_user.privilegio == 0) {
              state[APP].isDevUser = true;
              state[APP].isAdmin   = true;
            }
            else if (logged_user.privilegio == 1 || logged_user.privilegio == 2) {
              state[APP].isAdmin   = true;
            }
            else {
              state[USERS] = [logged_user];
            }

            APP_DEBUG_MODE && console.log("FETCHED_ALL - USERS: ", state[USERS]);
            
            actions[CLIENTES].FETCH_ALL(() => {
              actions[PLANES].FETCH_ALL(() => {
                state[APP].appFullyLoaded = true;
                APP_DEBUG_MODE && console.log("[Login.vue - App Fully Loaded]: ", state[APP].appFullyLoaded);

                loading.value = false;

                setTimeout(() => {
                  if (state[APP].isDevUser)
                    vm.proxy.$root.$router.replace("/cuenta_usuario");
                  else
                    vm.proxy.$root.$router.replace("/cobranza");

                  vm.proxy.$root.$toast.success("Sesión iniciada", { timeout: 2000 });
                  fullPageLoading.value = false;
                }, 500);
              });
            });
          });
        }
      });
    }

    return {
      PASSWORD_MIN, 

      showpwd,
      email,
      contrasena,
      loading,

      handleSubmit,

      fullPageLoading,

      // Funcs. de validacion
      FormValidations,
    }
  },
};
</script>